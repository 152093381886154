export const BASE_URL = process.env.REACT_APP_API_URL
export const MATTERPORT_URL = process.env.MATTER_PORT_SRC
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const AWS_IMAGE_URL = process.env.REACT_APP_AWS_IMAGE_URL_KEY
export const REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL
export const REACT_APP_REDIRECT_URL_FAQ = process.env.REACT_APP_REDIRECT_URL_FAQ
export const AUTH_LOGIN_ID = process.env.REACT_APP_AUTH_LOGIN_ID
export const AUTH_PUBLIC_KEY = process.env.REACT_APP_AUTH_PUBLIC_KEY
export const SENTRY_DNS= process.env.REACT_APP_SENTRY_DSN
// Toast Settings
export const TOAST_HANG_TIME : number = parseInt(process.env.REACT_APP_TOAST_HANG_TIME ?? "5000");
export const REACT_APP_REDIRECT_URL_PORTFOLIO = process.env.REACT_APP_REDIRECT_URL_PORTFOLIO
export const REACT_APP_REDIRECT_URL_SERVICES = process.env.REACT_APP_REDIRECT_URL_SERVICES
export const REACT_APP_REDIRECT_URL_LOGIN = process.env.REACT_APP_REDIRECT_URL_LOGIN